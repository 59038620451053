import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  SupportType,
  ApiResponseType,
  ParentType,
  RegionType,
  VerticalType,
  OperatorType,
  OptionType,
  TitleType,
  CountryType,
  ChipsetType,
  FrequencyBandType,
  TechType,
  VerticalTreeType,
  CountryTreeType,
  YearlyGrowth,
} from "../../types";
import {
  chipsetsValuesToParams,
  ChipsetsValuesType,
  devicesValuesToParams,
  DevicesValuesType,
  networksValuesToParams,
  NetworksValuesType,
  privateNetworksValuesToParams,
  PrivateNetworksValuesType,
  AllocationsValuesType,
  allocationsValuesToParams,
  operatorsValuesToParams,
  OperatorsValuesType,
} from "../../utils";
import { baseUrl, prepareHeaders } from "../../constants/api";

const searchApi = createApi({
  reducerPath: "search",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
    fetchFn: async (resource, options) => {
      const response = await fetch(resource, options);
      if (response.status === 401) {
        localStorage.removeItem("gambod");
        window.location.href = "/";
      }
      return response;
    },
  }),
  endpoints: builder => ({
    getAllocations: builder.query<ApiResponseType, AllocationsValuesType>({
      query: values => `allocations?return_format=json&per_page=-1&page=1&output_format=lazy${allocationsValuesToParams(values)}`,
    }),
    getAllocationsV2: builder.query<ApiResponseType, AllocationsValuesType>({
      query: values => `allocation-overview?${allocationsValuesToParams(values)}`,
    }),
    exportChipsets: builder.query<string | undefined, {values: ChipsetsValuesType, fields: string[]}>({
      query: ({values, fields}) => `chipsets?output_format=csv&per_page=-1${chipsetsValuesToParams(values)}&fields=${fields}`,
      transformResponse: (response: ApiResponseType) => response.download,
    }),
    getChipsets: builder.query<
      ApiResponseType,
      {
        values?: Partial<ChipsetsValuesType>;
        rowsPerPage?: number;
        page?: number;
        sortBy?: string;
        sortDirection?: string;
      }
    >({
      query: ({ rowsPerPage = -1, page = 1, values, sortBy = "id", sortDirection = "asc" }) =>
        `chipsets?output_format=json&per_page=${rowsPerPage}&page=${page}&order_by=${sortBy}&order_direction=${sortDirection}${
          values ? chipsetsValuesToParams(values) : ""
        }`,
    }),
    getChipset: builder.query<ChipsetType, string>({
      query: id => `chipset/${id}`,
    }),
    getChipsetTypes: builder.query<SupportType[], void>({
      query: () => "chipset-types",
      transformResponse: (response: { results: SupportType[] }) => response.results,
    }),
    getChipsetVendors: builder.query<SupportType[], void>({
      query: () => "chipset-vendors",
      transformResponse: (response: { results: SupportType[] }) => response.results,
    }),
    getCountries: builder.query<CountryType[], { ituRegions: number[]; regions: number[] }>({
      query: ({ ituRegions, regions }) =>
        `countries${
          ituRegions.length
            ? `?itu_region_id=${encodeURIComponent(ituRegions.join())}`
            : regions.length
            ? `?region_id=${encodeURIComponent(regions.join())}`
            : ""
        }`,
      transformResponse: (response: { results: CountryType[] }) => response.results,
    }),
    getDevices: builder.query<
      ApiResponseType,
      {
        rowsPerPage: number;
        page: number;
        values: DevicesValuesType;
        time?: string;
        type?: string;
        sortBy?: string;
        sortDirection?: string;
      }
    >({
      query: ({ type, rowsPerPage, page, values, time, sortBy, sortDirection }) =>
        `devices?return_format=json${
          type ? "&output_format=" + type : ""
        }&per_page=${rowsPerPage}&page=${page}&order_by=${sortBy}&order_direction=${sortDirection}${
          time ? "&time_format=" + time : ""
        }${devicesValuesToParams(values)}`,
    }),
    getDevices4gTotal: builder.query<number, void>({
      query: () => "devices?return_format=json&technology_id=6&page=1&per_page=1",
      transformResponse: (response: { total: number }) => response.total,
    }),
    getDevices5gTotal: builder.query<number, void>({
      query: () => "devices?return_format=json&technology_id=5&page=1&per_page=1",
      transformResponse: (response: { total: number }) => response.total,
    }),
    exportDevices: builder.query<string | undefined, {values: DevicesValuesType, fields: string[]}>({
      query: ({values, fields}) => `devices?output_format=csv&per_page=-1${devicesValuesToParams(values)}&fields=${fields}`,
      transformResponse: (response: ApiResponseType) => response.download,
    }),
    getDeviceVendors: builder.query<any[], void>({
      query: () => "device-vendors",
      transformResponse: (response: { results: any[] }) => response.results,
    }),
    getFallbacks: builder.query<SupportType[], number[]>({
      query: technologies => `fallbacks${technologies.length ? `?technology_id=${encodeURIComponent(technologies.join())}` : ""}`,
      transformResponse: (response: { results: SupportType[] }) => response.results,
    }),
    getFeatures: builder.query<SupportType[], void>({
      query: () => "features",
      transformResponse: (response: { results: SupportType[] }) => response.results,
    }),
    getStatuses: builder.query<SupportType[], void>({
      query: () => "statuses?section=public_network",
      transformResponse: (response: { results: SupportType[] }) => response.results,
    }),
    getFormFactors: builder.query<TitleType[], void>({
      query: () => "form-factors",
      transformResponse: (response: { results: TitleType[] }) => response.results,
    }),
    getFrequencyBands: builder.query<OptionType[], number[]>({
      query: technologies =>
        `frequency-bands${technologies.length ? `?section_id=2&technology_id=${encodeURIComponent(technologies.join())}` : ""}`,
      transformResponse: (response: { results: FrequencyBandType[] }) => response.results,
    }),
    getFrequencyBandsAll: builder.query<FrequencyBandType[][], { technologies: number[]; sectionId: number }>({
      async queryFn({ technologies, sectionId }, _queryApi, _extraOptions, baseQuery) {
        const requests = [1, 2, 3].map(value => {
          return baseQuery(
            `frequency-bands?section_id=${sectionId}&frequency_group_id=${value}${
              technologies.length ? `&technology_id=${encodeURIComponent(technologies.join())}` : ""
            }`
          );
        });

        const initialValues: FrequencyBandType[][] = [[], [], []];
        const results: FrequencyBandType[][] = [];

        await Promise.allSettled(requests).then(responses => {
          responses.forEach(response => {
            if (response.status === "fulfilled") {
              const value = response.value as {
                data: { results: FrequencyBandType[] };
              };

              if (Array.isArray(value.data?.results)) {
                results.push([...value.data.results]);
              }
            }
          });
        });

        return {
          data: results.length === requests.length ? results : initialValues,
        };
      },
    }),
    getNetworks: builder.query<
      ApiResponseType,
      {
        rowsPerPage: number;
        page: number;
        values: NetworksValuesType;
        sortBy?: string;
        sortDirection?: string;
        format?: string;
      }
    >({
      query: ({ rowsPerPage, page, values, sortBy, sortDirection, format = "json" }) =>
        `public-networks?output_format=${format}&per_page=${format === "json" ? rowsPerPage : "-1"}&page=${
          format === "json" ? page : "1"
        }&order_by=${sortBy}&order_direction=${sortDirection}${networksValuesToParams(values)}`,
    }),
    getNetworksHeatMap: builder.query<any, { values: NetworksValuesType }>({
      query: ({ values }) => `public-networks/heat-map?${networksValuesToParams(values)}`,
    }),
    getPrivateNetworks: builder.query<
      ApiResponseType,
      {
        rowsPerPage: number;
        page: number;
        values: PrivateNetworksValuesType;
        sortBy?: string;
        sortDirection?: string;
        format?: string;
      }
    >({
      query: ({ rowsPerPage, page, values, sortBy, sortDirection, format = "json" }) =>
        `private-networks?output_format=${format}&per_page=${format === "json" ? rowsPerPage : "-1"}&page=${
          format === "json" ? page : "1"
        }&order_by=${sortBy}&order_direction=${sortDirection}${privateNetworksValuesToParams(values)}`,
    }),
    exportPublicNetworks: builder.query<string | undefined, {values: NetworksValuesType, fields: string[]}>({
      query: ({values, fields}) => `public-networks?output_format=csv&per_page=-1${networksValuesToParams(values)}&fields=${fields}`,
      transformResponse: (response: ApiResponseType) => response.download,
    }),
    exportPrivateNetworks: builder.query<string | undefined, {values: PrivateNetworksValuesType, fields: string[]}>({
      query: ({values, fields}) => `private-networks?output_format=csv&per_page=-1${privateNetworksValuesToParams(values)}&fields=${fields}`,
      transformResponse: (response: ApiResponseType) => response.download,
    }),
    getOperator: builder.query<OperatorType, string>({
      query: id => `operator/${id}`,
    }),
    getAllOperators: builder.query<OperatorType[], void>({
      query: () => "operators?return_format=json",
      transformResponse: (response: { results: OperatorType[] }) => response.results,
    }),
    getOperators: builder.query<
      ApiResponseType,
      {
        rowsPerPage: number;
        page: number;
        values: any;
        sortBy?: string;
        sortDirection?: string;
        format?: string;
      }
    >({
      query: ({ rowsPerPage, page, values, sortBy, sortDirection, format = "json" }) =>
        `operators?output_format=${format}&per_page=${format === "json" ? rowsPerPage : "-1"}&page=${
          format === "json" ? page : "1"
        }&order_by=${sortBy}&order_direction=${sortDirection}${operatorsValuesToParams(values)}`,
    }),
    exportOperators: builder.query<string | undefined, {values: OperatorsValuesType, fields: string[]}>({
      query: ({values, fields}) => `operators?output_format=csv&per_page=-1${operatorsValuesToParams(values)}&fields=${fields}`,
      transformResponse: (response: ApiResponseType) => response.download,
    }),
    getItuRegions: builder.query<RegionType[], void>({
      query: () => "itu-regions",
      transformResponse: (response: { results: RegionType[] }) => response.results,
    }),
    getSpectrumBands: builder.query<RegionType[], void>({
      query: () => "operator-networks/spectrum-bands",
      transformResponse: (response: { results: RegionType[] }) => response.results,
    }),
    getSatVendors: builder.query<RegionType[], void>({
      query: () => "satellite-vendors",
      transformResponse: (response: { results: RegionType[] }) => response.results,
    }),
    getSatNTN: builder.query<RegionType[], void>({
      query: () => "satellite-vendors/use-cases",
      transformResponse: (response: { results: RegionType[] }) => response.results,
    }),
    getSunsetTechs: builder.query<TechType[], void>({
      query: () => "operators/sunset-technology",
      transformResponse: (response: TechType[]) => response,
    }),
    getUpgradeTechs: builder.query<TechType[], void>({
      query: () => "operators/upgrade-technology",
      transformResponse: (response: TechType[]) => response,
    }),
    getVerticals: builder.query<VerticalType[], void>({
      query: () => "operator-networks/verticals",
      transformResponse: (response: { results: VerticalType[] }) => response.results,
    }),
    getVerticalsAsTreedata: builder.query<VerticalTreeType[], {values: PrivateNetworksValuesType}>({
      query: ({ values }) => `private-networks/chart/verticals?${privateNetworksValuesToParams(values)}`,
      transformResponse: (response: { results: VerticalTreeType[] }) => response.results,
    }),
    getCountriesAsTreedata: builder.query<CountryTreeType[], {values: PrivateNetworksValuesType}>({
      query: ({ values }) => `private-networks/chart/countries?${privateNetworksValuesToParams(values)}`,
      transformResponse: (response: { results: CountryTreeType[] }) => response.results,
    }),
    getYearlyGrowth: builder.query<YearlyGrowth[], {values: PrivateNetworksValuesType}>({
      query: ({ values }) => `private-networks/chart/announced-years?${privateNetworksValuesToParams(values)}`,
      transformResponse: (response: { results: YearlyGrowth[] }) => response.results,
    }),
    getRegions: builder.query<RegionType[], void>({
      query: () => "regions?level=%20parent%20",
      transformResponse: (response: { results: RegionType[] }) => response.results,
    }),
    getChildRegions: builder.query<RegionType[], void>({
      query: () => "regions?level=%20child%20",
      transformResponse: (response: { results: RegionType[] }) => response.results,
    }),
    getSettings: builder.query<any, string | void>({
      query: sectionName => `settings${sectionName ? `?key=last_updated_${sectionName}` : ""}`,
      transformResponse: (response: { value: any }) => response.value,
    }),
    getSupports: builder.query<SupportType[], number[]>({
      query: technologies => `supports${technologies.length ? `?technology_id=${encodeURIComponent(technologies.join())}` : ""}`,
      transformResponse: (response: { results: SupportType[] }) => response.results,
    }),
    getTechnologies: builder.query<ParentType[], number | void>({
      query: sectionId => `technologies${sectionId ? `?section_id=${sectionId}` : ""}`,
      transformResponse: (response: { results: ParentType[] }) => {
        let items = response.results;
        return items;
      },
    }),
    getTechnologiesByName: builder.query<ParentType[], string | void>({
      query: sectionName => `technologies${sectionName ? `?section=${sectionName}` : ""}`,
      transformResponse: (response: { results: ParentType[] }) => {
        let items = response.results;
        return items;
      },
    }),
    getUsage: builder.query<ParentType[], number | void>({
      query: sectionId => `usages?section=${sectionId ? sectionId : "public_network" }`,
      transformResponse: (response: { results: ParentType[] }) => {
        let items = response.results;
        return items;
      },
    }),
    getUeCategories: builder.query<SupportType[], void>({
      query: () => "ue-categories",
      transformResponse: (response: { results: SupportType[] }) => response.results,
    }),
    exportAllocations: builder.query<string | undefined, {values: AllocationsValuesType, fields: string[]}>({
      query: ({values, fields}) => `allocations?output_format=csv&per_page=-1${allocationsValuesToParams(values)}&fields=${fields}`,
      transformResponse: (response: ApiResponseType) => response.download,
    }),
    getAllocationsTotal: builder.query<number, void>({
      //query: () => "allocations?return_format=json&page=1&per_page=1",
      query: () => "allocations?return_format=json",
      transformResponse: (response: { total: number }) => response.total,
    }),
  }),
});

export const {
  useLazyExportAllocationsQuery,
  useLazyExportChipsetsQuery,
  useLazyExportDevicesQuery,
  useLazyExportPublicNetworksQuery,
  useLazyExportPrivateNetworksQuery,
  useLazyExportOperatorsQuery,
  useGetAllocationsQuery,
  useGetAllocationsV2Query,
  useGetChipsetQuery,
  useGetChipsetsQuery,
  useGetChipsetTypesQuery,
  useGetChipsetVendorsQuery,
  useGetCountriesQuery,
  useGetDevicesQuery,
  useGetDevices4gTotalQuery,
  useGetDevices5gTotalQuery,
  useGetDeviceVendorsQuery,
  useGetFallbacksQuery,
  useGetFeaturesQuery,
  useGetStatusesQuery,
  useGetFormFactorsQuery,
  useGetFrequencyBandsAllQuery,
  useGetFrequencyBandsQuery,
  useGetNetworksQuery,
  useGetNetworksHeatMapQuery,
  useGetPrivateNetworksQuery,
  useGetAllOperatorsQuery,
  useGetOperatorQuery,
  useGetOperatorsQuery,
  useGetItuRegionsQuery,
  useGetSpectrumBandsQuery,
  useGetSatVendorsQuery,
  useGetSatNTNQuery,
  useGetSunsetTechsQuery,
  useGetUpgradeTechsQuery,
  useGetVerticalsQuery,
  useGetVerticalsAsTreedataQuery,
  useGetCountriesAsTreedataQuery,
  useGetYearlyGrowthQuery,
  useGetRegionsQuery,
  useGetChildRegionsQuery,
  useGetSettingsQuery,
  useGetSupportsQuery,
  useGetTechnologiesQuery,
  useGetTechnologiesByNameQuery,
  useGetUsageQuery,
  useGetUeCategoriesQuery,
  useGetAllocationsTotalQuery,
} = searchApi;

export default searchApi;
