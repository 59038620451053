import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";

export interface ExportCsvProps {
  source?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
}

const ExportCsv: React.FC<ExportCsvProps> = ({ source, loading, disabled = false, onClick }) => {
  const useButtonStyles = makeStyles(() => ({
    label: {
      whiteSpace: "nowrap",
    },
  }));

  const buttonStyles = useButtonStyles();
  const [download, setDownload] = useState(source);

  useEffect(() => {
    if (source && source !== download) {
      const anchor = document.createElement("a");

      if (anchor) {
        anchor.href = source;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }

      setDownload(source);
    }
  }, [source, download]);

  const useTooltipStyles = makeStyles(() => ({
    popper: {
      zIndex: 2000,
    },
  }));
  const tooltipStyles = useTooltipStyles();

  if (!disabled) {
    return (
      <Button
        classes={buttonStyles}
        variant="outlined"
        onClick={onClick}
        startIcon={loading ? <CircularProgress size={20} thickness={6} /> : <GetApp />}
      >
        Export CSV
      </Button>
    );
  } else {
    return (
      <Tooltip title={disabled ? "Exports are limited to 2,000 records" : "Export CSV"} arrow placement="top" classes={tooltipStyles}>
        <Box>
          <Button
            classes={buttonStyles}
            variant="outlined"
            onClick={onClick}
            disabled={disabled}
            startIcon={loading ? <CircularProgress size={20} thickness={6} /> : <GetApp />}
          >
            Export CSV
          </Button>
        </Box>
      </Tooltip>
    );
  }
};

export default ExportCsv;
