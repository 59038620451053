import BarChartIcon from "@mui/icons-material/BarChart";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import PieChartIcon from "@mui/icons-material/PieChart";
import { TabContext, TabPanel } from "@mui/lab";
import {
  AppBar,
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  LinearProgress,
  Link,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import ContentWrapper from "../components/ContentWrapper";
import DevicesBarChart from "../components/DevicesBarChart";
import DevicesPieChart from "../components/DevicesPieChart";
import ExportCsv from "../components/ExportCsv";
import FilterNav from "../components/FilterNav";
import NoResults from "../components/NoResults";
import PageHeading from "../components/PageHeading";
import SaveSearch from "../components/SaveSearch";
import TableAdvanced from "../components/TableAdvanced";
import { devicesCells } from "../constants";
import allRoutes from "../constants/routes";
import { useSaveSearchMutation } from "../store/apis/save";
import { useGetDevicesQuery, useLazyExportDevicesQuery } from "../store/apis/search";
import { selectSaveToken } from "../store/slices/auth";
import { selectDevicesSlice, setPage, setRowsPerPage, setSavedValues, setSortBy } from "../store/slices/devices";
import { selectUiState, setDense, setFontSize } from "../store/slices/ui";
import { displayDate, mapValuesToSaveData } from "../utils";

const DevicesSearch: React.FC = () => {
  const dispatch = useDispatch();
  const { page, rowsPerPage, sortBy, sortDesc, values, lastSaved } = useSelector(selectDevicesSlice);
  const { dense, fontSize, exportFields } = useSelector(selectUiState);
  const token = useSelector(selectSaveToken);
  const theme = useTheme();

  const useAppBarStyles = makeStyles(() => ({
    root: {
      backgroundColor: "white",
    },
    positionSticky: {
      [theme.breakpoints.up("lg")]: {
        top: 52,
      },
    },
  }));
  const appBarStyles = useAppBarStyles();
  const location = useLocation();
  const lastPart = location.pathname.split("/").pop() || "search";

  const [time, setTime] = useState("years");
  const [type, setType] = useState("chart_vendor");
  const [viewCurrent, setView] = useState(lastPart);

  const [saveSearch, { isLoading: isSaving }] = useSaveSearchMutation();
  const [trigger, { data: download, isLoading: isExporting }] = useLazyExportDevicesQuery();

  const {
    data = { results: [], total: 0 },
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetDevicesQuery({
    rowsPerPage: lastPart === "search" ? rowsPerPage : -1,
    page: lastPart === "search" ? page : 1,
    values,
    type: lastPart === "bar" ? "chart_time" : lastPart === "pie" ? type : "",
    time: lastPart === "bar" ? time : "",
    sortBy,
    sortDirection: sortDesc ? "desc" : "asc",
  });

  const { results = [], total = 0 } = data;
  const loading = isLoading || isFetching || isUninitialized;
  const pages = Math.ceil(total / rowsPerPage);

  useEffect(() => {
    if (lastPart !== viewCurrent && !loading) {
      setView(lastPart);
    }
  }, [results, loading, lastPart, viewCurrent]);

  const handleSave = () => {
    dispatch(setSavedValues(values));
    saveSearch({ sectionId: 1, data: mapValuesToSaveData(1, values) });
  };

  const isTablet = useMediaQuery(theme.breakpoints.up("sm"));

  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // console.log(newValue);
    if (newValue === "pie") {
      navigate(`${allRoutes.devices.path}/search/pie`);
    } else if (newValue === "bar") {
      navigate(`${allRoutes.devices.path}/search/bar`);
    } else if (newValue === "search") {
      navigate(`${allRoutes.devices.path}/search`);
    }
  };

  const renderButtons = () => (
    <Box paddingY={4}>
      <Grid container spacing={4} alignItems="center">
        <Grid item zeroMinWidth>
          <SaveSearch disabled={isEqual(values, lastSaved)} loading={isSaving} onClick={handleSave} />
        </Grid>
        <Grid item zeroMinWidth>
          <ExportCsv
            disabled={total > 2000}
            source={download}
            loading={isExporting}
            onClick={() => trigger({ values: values, fields: exportFields })}
          />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      <Helmet title="Devices - GSA GAMBoD" />
      <FilterNav />
      <PageHeading title={allRoutes.devices.label} icon={allRoutes.devices.icon} color={allRoutes.devices.color} section="devices" inset>
        <Breadcrumbs aria-label="Breadcrumb">
          <Link href={allRoutes.dashboard.path}>Dashboard</Link>
          <Link href={allRoutes.devices.path}>Devices</Link>
          <Typography>Search</Typography>
        </Breadcrumbs>
      </PageHeading>
      <ContentWrapper inset>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs zeroMinWidth>
            <Box paddingY={10}>
              <Grid container spacing={8} alignItems="flex-end" wrap="nowrap">
                <Grid item zeroMinWidth sx={{ display: { xs: "none", md: "block" } }}>
                  <Typography variant="h4">Your Results</Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <Typography variant="subtitle2" gutterBottom>
                    Returned results
                  </Typography>
                  <Typography variant="h4">
                    <span data-testid="devicesTotal" data-testvalue={total}>
                      {total.toLocaleString()}
                    </span>{" "}
                    Devices
                  </Typography>
                </Grid>
                {values.startDate && (
                  <Grid item zeroMinWidth>
                    <Typography variant="subtitle2" gutterBottom>
                      Date range of rows
                    </Typography>
                    <Typography variant="h4">{`${displayDate(values.startDate)} to ${displayDate(values.endDate)}`}</Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item zeroMinWidth sx={{ display: { xs: "none", md: "block" } }}>
            {renderButtons()}
          </Grid>
        </Grid>
      </ContentWrapper>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <ContentWrapper inset>
          {renderButtons()}
          <Divider />
        </ContentWrapper>
      </Box>
      <Divider />
      <TabContext value={lastPart}>
        <AppBar position="sticky" component="div" classes={appBarStyles}>
          {loading ? (
            <Box width="100%" position="relative" height={4}>
              <LinearProgress />
            </Box>
          ) : (
            <Box width="100%" position="relative" style={{ background: "#fff" }} height={4}></Box>
          )}
          <ContentWrapper body inset>
            <Box paddingTop={3}>
              <Tabs value={lastPart} onChange={handleChange} indicatorColor="primary" textColor="primary">
                <Tab
                  label="Table"
                  value="search"
                  data-testid="table-tab"
                  icon={<CalendarViewMonthIcon />}
                  iconPosition="start"
                  style={{
                    paddingLeft: isTablet ? "40px" : "20px",
                    paddingRight: isTablet ? "40px" : "20px",
                  }}
                />
                <Tab
                  label="Pie Chart"
                  value="pie"
                  data-testid="pie-chart-tab"
                  icon={<PieChartIcon />}
                  iconPosition="start"
                  style={{
                    minWidth: "180px",
                    paddingLeft: isTablet ? "40px" : "20px",
                    paddingRight: isTablet ? "40px" : "20px",
                  }}
                />
                <Tab
                  label="Bar Chart"
                  value="bar"
                  icon={<BarChartIcon />}
                  iconPosition="start"
                  style={{
                    minWidth: "185px",
                    paddingLeft: isTablet ? "40px" : "20px",
                    paddingRight: isTablet ? "40px" : "20px",
                  }}
                />
              </Tabs>
            </Box>
            <Divider />
          </ContentWrapper>
        </AppBar>
        <ContentWrapper body inset>
          <Box minHeight={500}>
            <TabPanel value="bar">
              {lastPart !== viewCurrent ? (
                <></>
              ) : !loading && !results.length ? (
                <NoResults />
              ) : (
                <DevicesBarChart time={time} data={results} setTime={setTime} />
              )}
            </TabPanel>
            <TabPanel value="pie">
              {lastPart !== viewCurrent ? (
                <></>
              ) : !loading && !results.length ? (
                <NoResults />
              ) : (
                <DevicesPieChart data={results} type={type} loading={loading} setType={setType} />
              )}
            </TabPanel>
            <TabPanel value="search">
              {lastPart !== viewCurrent ? (
                <></>
              ) : !loading && !results.length ? (
                <NoResults />
              ) : (
                <TableAdvanced
                  title={`GAMBoD Devices Search - ${total.toLocaleString()} results, page ${page}`}
                  dense={dense}
                  rows={results}
                  fontSize={fontSize}
                  cellsMap={devicesCells(sortBy, sortDesc, values)}
                  page={page}
                  pages={pages}
                  rowsPerPage={rowsPerPage}
                  loading={loading}
                  setRowsPerPage={value => {
                    dispatch(setRowsPerPage(value));
                  }}
                  setPage={value => {
                    dispatch(setPage(value));
                  }}
                  setFontSize={value => {
                    dispatch(setFontSize(value));
                  }}
                  setDense={value => {
                    dispatch(setDense(value));
                  }}
                  onOrder={value => {
                    dispatch(setSortBy(value));
                  }}
                />
              )}
            </TabPanel>
          </Box>
        </ContentWrapper>
      </TabContext>
    </>
  );
};

export default DevicesSearch;
